import axios from '../axios';
import axiosFileDownloader from '../axiosFileDownloader';
import {v4 as uuidv4} from 'uuid';
import {
    changeFormatOfDateString,
    findIndex,
    getDateObj,
    getDateString,
    getSorted,
    numberWithThousandSeparator,
} from '../../services/CommonService';

class ProductionScheduleService {

    static Instance() {
        return new ProductionScheduleService();
    }

    getAutoProductionSchedule() {
        return axios.post('api/production/schedule/v1');
    }

    rebuildProductionSchedule(isRebuildToday) {
        let url = isRebuildToday ? "api/production/schedule/slots/reschedule/include-today" : "api/production/schedule/slots/reschedule/exclude-today";
        return axios.put(url, {}, {
            timeout: 360 * 1000
        });
    }

    updateProductionSchedule(data) {
        return axios.post('api/production/schedule/quick/update', data);
    }

    updateProductionDeschedule(data) {
        return axios.post('api/production/deschedule/quick/update', data);
    }

    updateProductionCalendarClose(data) {
        return axios.post('api/production/schedule/calendar/close/toogle', data);
    }

    updateProductionSalesOrderJobComplete(request) {
        return axios.post('api/production/schedule/sales-order-job/status/update/complete', request);
    }

    pickProductionSalesOrderJob(salesOrderJobID) {
        return axios.post('api/production/schedule/salesOrderJob/pick?salesOrderJobID=' + salesOrderJobID);
    }

    downloadLabels(data) {
        return axiosFileDownloader.post('api/production/labels/download', data);
    }

    getStore(productionLineShortName) {

        let store = {};

        switch (productionLineShortName) {
            case 'CURTAIN TR':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    },
                    {
                        key: "isPicked",
                        label: "Is Picked",
                        type: "isPicked",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "Click to mark it picked",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "salesOrderJobIsCompleted",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'VENETIANS':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    },
                    {
                        key: "isPicked",
                        label: "Is Picked",
                        type: "isPicked",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "Click to mark it picked",
                        isDisabled: false,
                        minWidth: '50px'
                    },{
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'VERT OUT':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "hasPowderCoating",
                        label: "Powder Coating",
                        type: "boolean",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "hasPelmet",
                        label: "Pelmet",
                        type: "boolean",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'VERT IN':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    },
                    {
                        key: "isPicked",
                        label: "Is Picked",
                        type: "isPicked",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "Click to mark it picked",
                        isDisabled: false,
                        minWidth: '50px'
                    },{
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'ROLLER':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    },
                    {
                        key: "isPicked",
                        label: "Is Picked",
                        type: "isPicked",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "Click to mark it picked",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'FOLD ARM':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "sum",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "hasPowderCoating",
                        label: "Powder Coating",
                        type: "boolean",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "hasPelmet",
                        label: "Pelmet",
                        type: "boolean",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'PANEL TR':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'COMPONENTS':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "itemCount",
                        label: "Lines",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'CUT IN':
            case 'CUT OUT':
                store = [
                    {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    },
                    /*{
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    },*/
                    {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "qtyUsed",
                        label: "Qty used(in mtr.)",
                        type: "qtyUsed",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '40px'
                    },
                    {
                        key: "keywayQOH",
                        label: "",
                        type: "keywayQOH",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '10px'
                    },
                    {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            case 'BISTRO':
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    },
                    {
                        key: "isPicked",
                        label: "Is Picked",
                        type: "isPicked",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "Click to mark it picked",
                        isDisabled: false,
                        minWidth: '50px'
                    },{
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
            default:
                store = [
                    {
                        key: "custOrdDate",
                        label: "Cust Order Date",
                        type: "utcDateString",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "ordNum",
                        label: "Order No",
                        type: "link_order_enquiry_view_mode_production",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '60px'
                    }, {
                        key: "delCompany",
                        label: "Customer",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "custOrdNum",
                        label: "Cust Ord Num",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '80px'
                    }, {
                        key: "qty",
                        label: "Qty",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "custRequiredDate",
                        label: "Cust Required Date",
                        type: "utcDateString",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "daysOverDue",
                        label: "Days OD",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "notes",
                        label: "Notes",
                        type: "default",
                        extendedColumn: true,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "fabric",
                        label: "Description",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '100px'
                    }, {
                        key: "product",
                        label: "Product",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "salesOrderJobStatusCode",
                        label: "Status",
                        type: "status",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "salesOrderJobStatusDescription",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "labelPrintedDate",
                        label: "Label Date",
                        type: "labelPrintedDate",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "whStatus",
                        label: "Prod Notes",
                        type: "whStatus",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule On",
                        type: "scheduleOn",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: true,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "isDescheduled",
                        label: "",
                        type: "descheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: false,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "scheduleOn",
                        label: "Schedule",
                        type: "scheduleButton",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "followUpDate",
                        label: "Follow up on",
                        type: "followUpDatePicker",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleReasonName",
                        label: "Reason",
                        type: "default",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '50px'
                    }, {
                        key: "productionDescheduleNotes",
                        label: "Notes",
                        type: "productionDescheduleNotes",
                        extendedColumn: false,
                        isScheduleColumn: false,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "",
                        isDisabled: false,
                        minWidth: '200px'
                    }, {
                        key: "salesOrderJobStatus",
                        label: "Is Completed",
                        type: "completeStatusButton",
                        extendedColumn: false,
                        isScheduleColumn: true,
                        isDescheduleColumn: true,
                        isCSVColumn: false,
                        title: "Click to mark it complete",
                        isDisabled: false,
                        minWidth: '50px'
                    }];
                break;
        }
        return store;
    }

    convertOrder(order) {
        order.scheduleOn = order.scheduleOn ? getDateString(order.scheduleOn, "DD/MM/YYYY") : "";
        order.followUpDate = order.followUpDate ? getDateString(order.followUpDate, "DD/MM/YYYY") : "";
        order.guid = uuidv4();
        order.notes = order.notes ? order.notes.trim() : "";
        return order;
    }

    generateScheduleBucketKey(productionLineId, scheduleOn) {
        return "ProductionLineID_" + productionLineId + "scheduleOn_" + scheduleOn;
    }

    prepareProductionLinesResponseData(data) {
        let productionLinesSchedule = [], id = 0;
        data.indexByProductionLineId = {};
        data.productionLines.map((productionLine, productionLineIndex) => {
            data.indexByProductionLineId[productionLine.productionLineId] = productionLineIndex;
            productionLinesSchedule.push({
                id: productionLine.productionLineId,
                productionLine: productionLine,
                orders: []
            });
        });
        data.orders.map((order, orderIndex) => {
            productionLinesSchedule[data.indexByProductionLineId[order.productionLineID]].orders.push(this.convertOrder(order));
        });
        return productionLinesSchedule;
    }

    prepareProductionLineSchedule(productionLineSchedule, collapseTriggers) {
        let scheduleBucketIndex = -1, customProductionCapacityIndex = -1, identityKey = "";
        productionLineSchedule.scheduleBuckets = [];

        productionLineSchedule.orders = getSorted(productionLineSchedule.orders, 'sequence', true);

        productionLineSchedule.orders.map((order, orderIndex) => {

            scheduleBucketIndex = findIndex(productionLineSchedule.scheduleBuckets, 'scheduleOn', order.scheduleOn);

            if (order.scheduleOn) {
                customProductionCapacityIndex = findIndex(productionLineSchedule.customProductionCapacities, 'productionDate', order.scheduleOn);
            } else {
                customProductionCapacityIndex = -1;
            }

            if (!(scheduleBucketIndex > -1)) {
                identityKey = this.generateScheduleBucketKey(productionLineSchedule.productionLine.productionLineId, order.scheduleOn ? order.scheduleOn : "DeScheduled");

                let item = {
                    identityKey: identityKey,
                    orders: [],
                    scheduleQty: 0,
                    itemCount: 0,
                    scheduleOn: order.scheduleOn,
                    store: order.scheduleOn ? productionLineSchedule.store_schedule : productionLineSchedule.store_deschedule,
                    label: order.scheduleOn ? changeFormatOfDateString(order.scheduleOn, "DD/MM/YYYY", "ddd DDMMMYY") : "DeScheduled",
                    scheduleOnDateObject: order.scheduleOn ? getDateObj(order.scheduleOn, "DD/MM/YYYY") : null,
                    productionCapacity: customProductionCapacityIndex > -1 ? productionLineSchedule.customProductionCapacities[customProductionCapacityIndex].capacity : productionLineSchedule.productionLine.defaultCapacity,
                    isOpen: true,
                    csv: {
                        data: [],
                        filename: productionLineSchedule.productionLine.code + "_" + (order.scheduleOn ? changeFormatOfDateString(order.scheduleOn, "DD/MM/YYYY", "DDMMMYY") : "DeScheduled") + ".csv"
                    },
                    productionLine: productionLineSchedule.productionLine,
                    isProductionDateClosed: order.isProductionDateClosed,
                };
                productionLineSchedule.scheduleBuckets.push(item);
                scheduleBucketIndex = productionLineSchedule.scheduleBuckets.length - 1;
            }

            order.bucketIdentityKey = productionLineSchedule.scheduleBuckets[scheduleBucketIndex].identityKey;
            order.scheduleOnLabel = productionLineSchedule.scheduleBuckets[scheduleBucketIndex].label;
            order.scheduleOnDateObject = productionLineSchedule.scheduleBuckets[scheduleBucketIndex].scheduleOnDateObject;
            order.followUpDateLabel = order.followUpDate ? changeFormatOfDateString(order.followUpDate, "DD/MM/YYYY", "ddd DDMMMYY") : null;
            order.followUpDateObject = order.followUpDate ? getDateObj(order.followUpDate, "DD/MM/YYYY") : null;
            order.custOrdDateLocalDateString = getDateString(order.custOrdDate, 'DDMMMYY');
            order.custRequiredDateLocalDateString = getDateString(order.custRequiredDate, 'DDMMMYY');
            order.productionLineName = productionLineSchedule.productionLine.name;
            order.productionLineShortName = productionLineSchedule.productionLine.shortName;

            productionLineSchedule.scheduleBuckets[scheduleBucketIndex].itemCount += order.itemCount;
            productionLineSchedule.scheduleBuckets[scheduleBucketIndex].scheduleQty += order.qty;
            productionLineSchedule.scheduleBuckets[scheduleBucketIndex].orders.push(order);

            productionLineSchedule.scheduleBuckets[scheduleBucketIndex].csv.data.push(productionLineSchedule.csv.store.map((storeItem) => this.getColCSVValue(storeItem, order)));

        });

        productionLineSchedule.scheduleBuckets.sort(function (a, b) {
            return a.scheduleOnDateObject - b.scheduleOnDateObject;
        });


        // open deschedule and first date bucket panel by default
        let length = productionLineSchedule.scheduleBuckets.length < 2 ? productionLineSchedule.scheduleBuckets.length : 2;
        for (let i = 0; i < length; i++) {
            identityKey = this.generateScheduleBucketKey(productionLineSchedule.productionLine.productionLineId, productionLineSchedule.scheduleBuckets[i].scheduleOn ? productionLineSchedule.scheduleBuckets[i].scheduleOn : "DeScheduled");
            if (collapseTriggers[identityKey] === undefined) {
                productionLineSchedule.scheduleBuckets[i].isOpen = true;
            } else {
                productionLineSchedule.scheduleBuckets[i].isOpen = collapseTriggers[identityKey];
            }
        }

        if (productionLineSchedule.productionLine.code === "CIN" || productionLineSchedule.productionLine.code === "COD") {
            let counter = 0;
            let _self = this;
            productionLineSchedule.exportFirstFiveBucketCSV = {store: [], headers: [], data: [], filename: ''};
            productionLineSchedule.exportFirstFiveBucketCSV.headers = productionLineSchedule.csv.headers;
            productionLineSchedule.exportFirstFiveBucketCSV.store = productionLineSchedule.csv.store;
            productionLineSchedule.exportFirstFiveBucketCSV.filename = productionLineSchedule.productionLine.code + "__Scheduledbuckets.csv";
            productionLineSchedule.scheduleBuckets.forEach(function (item) {
                //check if its a De-Scheduled bucked OR Scheduled bucket
                if (item.scheduleOn) {   //if true means its a Scheduled bucket
                    // We'll only export_data of  first 5 Scheduled buckets and we'll only make them open
                    if (counter < 5) {
                        counter++;
                        item.isOpen = true;
                        if (item.orders) {
                            item.orders.map((order, orderIndex) => {
                                productionLineSchedule.exportFirstFiveBucketCSV.data.push(productionLineSchedule.csv.store.map((storeItem) => _self.getColCSVValue(storeItem, order)));
                            });
                        }
                    }
                }
            });
        }
        return productionLineSchedule;
    }

    getColCSVValue(storeItem, order) {
        let value = order[storeItem.key];
        switch (storeItem.type) {
            case "utcDateString":
                if (storeItem.key === "custRequiredDate") {
                    return order.custRequiredDateLocalDateString;
                }
                if (storeItem.key === "custOrdDate") {
                    return order.custOrdDateLocalDateString;
                }
                return getDateString(value, 'DDMMMYY');
            case "boolean":
                if (value === 1) {
                    return "Yes";
                }
                if (value === 0) {
                    return "No";
                }
                if (value === "") {
                    return "";
                }
                if (value) {
                    return "Yes";
                }
                return "No";
            case "currency":
                return "$" + numberWithThousandSeparator(value);
            case "number":
                return numberWithThousandSeparator(value);
            case "scheduleOn":
                return order.scheduleOn;
            default:
                return (value === undefined || value === null) ? "" : value;
        }
    }

    getSalesOrderJobPowdercoatingItems(salesOrderJobID) {
        return axios.get('api/sales/order/production/job/powdercoat/one?salesOrderJobID=' + salesOrderJobID);
    }

    saveSalesOrderJobPowdercoatingItems(req) {
        return axios.post('api/sales/order/production/job/powdercoat/one/save', req);
    }

    saveSalesOrderJobBulkPowdercoatingPurchaseOrder(req) {
        return axios.post('api/sales/order/production/job/powdercoat/save/bulk/purchase/order', req);
    }

    createPurchaseOrderDocument(purchaseOrdNum) {
        return axios.post('api/sales/order/production/job/powdercoat/one/save/purchase-order/attachment?purchaseOrdNum=' + purchaseOrdNum);
    }

    emailSalesOrderJobPowdercoatPurchaseOrder(req) {
        return axios.post('api/sales/order/production/job/powdercoat/one/purchase-order/share/email', req);
    }

    updateProductionSalesOrderJob(salesOrderJobID, statusID) {
        return axios.post('api/production/schedule/sales-order-job/status/update?SalesOrderJobID=' + salesOrderJobID + '&StatusID=' + statusID);
    }

    updateProductionDescheduleNotes(request) {
        return axios.put('api/production/schedule/sales-order-job/productionDescheduleNotes/update', request);
    }
    updateProductionScheduleJobPriority(request) {
        return axios.post('api/production/schedule/update/job/priority', request);
    }
    updateProductScheduleProdNotes(request) {
        return axios.post('api/production/schedule/sales-order-job/production/notes/update', request);
    }
    exportFabricCutData(request) {
        return axiosFileDownloader.post("api/production/machine-data/export", request);
    }
    downloadAndCut(request) {
        return axiosFileDownloader.post('api/production/download/and/cut/fabric', request);
    }
    scheduleOrderQuick(request) {
        return axios.post('api/production/schedule/order/quick', request);
    }

}

export default ProductionScheduleService.Instance();