import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Spinner
} from 'reactstrap';

import DatePicker from "react-datepicker";
import classnames from 'classnames';
import { getDateString, getDateObj, handleErrorMessage, dateToFromNowDaily } from '../../services/CommonService';
import productionScheduleService from '../../services/production/ProductionScheduleService';
import { toast } from 'react-toastify';

const minDate = new Date();
export default class UpdateSalesOrderScheduleModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ordNum: props.ordNum,
            scheduleOn: getDateString(new Date(), "DD/MM/YYYY"),
            isScheduling: false,
            isAutoScheduling: false
        };
        this.getScheduleOnDatePickerCSSClassName = this.getScheduleOnDatePickerCSSClassName.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(key, change) {
        switch (key) {
            case "scheduleOn":
                this.setState({ scheduleOn: getDateString(change, "DD/MM/YYYY"), isAutoScheduling: false });
                break;
            case "isAutoScheduling":
                this.setState({
                    isAutoScheduling: change,
                });
                break;
            default:
                break;
        }
    }


    handleSubmit() {
        let { scheduleOn, ordNum, isAutoScheduling } = this.state;
        let request = {
            scheduleOn: scheduleOn,
            ordNum: ordNum,
            isAutoScheduling: this.state.isAutoScheduling
        };
        this.setState({ isScheduling: true });
        productionScheduleService.scheduleOrderQuick(request).then(response => {
            this.setState({ isScheduling: false });
            toast.success("Scheduled!");
            this.props.toggle(!this.props.isOpen);
            this.props.refresh();
        }).catch(error => {
            this.setState({ isScheduling: false });
            toast.error(handleErrorMessage(error));
            this.props.toggle(!this.props.isOpen);
        });
    }


    getScheduleOnDatePickerCSSClassName(isAutoScheduling) {
        let cssClassName = "";
        switch (isAutoScheduling) {
            case true:
                cssClassName = "";
                break;
            case false:
                cssClassName = "text-danger";
                break;
        }
        return cssClassName;
    }


    render() {
        let { isOpen, toggle } = this.props;
        let { ordNum, scheduleOn, isScheduling, isAutoScheduling } = this.state;


        return (
            
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Schedule Production #{ordNum}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex flex-row">
                            <div style={{ marginTop: 3 }}>
                                Schedule On
                            </div>

                            <div>
                                <DatePicker
                                    className={"btn btn-link btn-sm schedule-datepicker " + this.getScheduleOnDatePickerCSSClassName(isAutoScheduling)}
                                    selected={getDateObj(scheduleOn, "DD/MM/YYYY")}
                                    onChange={date => this.handleChange("scheduleOn", date)}
                                    dateFormat="ddMMMyy"
                                    placeholderText="Select date"
                                    minDate={minDate}
                                    withPortal
                                />
                            </div>
                            <div style={{ marginTop: 3 }}>({dateToFromNowDaily(getDateObj(scheduleOn, "DD/MM/YYYY"))})
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div>
                        <span className={"mr-2"}>
                            Allow Auto-Scheduling?
                        </span>
                        <Button color={"link"}
                            onClick={() => this.handleChange("isAutoScheduling", !isAutoScheduling)}>
                            <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": isAutoScheduling,
                                "fa-square-o": !isAutoScheduling,
                            }
                            )} />
                        </Button>
                    </div>
                    <small className="text-info">This will set the selected order to be ‘auto-scheduled’ on the next
                        Rescheduling run. This is used to undo a manual schedule on action.
                    </small>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"}
                            size={"sm"}
                            disabled={isScheduling}
                            className={"mr-2"}
                            onClick={this.handleSubmit}>
                            {isScheduling
                                ? <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                                : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />}
                            Schedule
                        </Button>
                        <Button color={"secondary"}
                            size={"sm"}
                            disabled={isScheduling}
                            onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>

                </ModalFooter>
                
            </Modal>
        
            
        )

    }
}